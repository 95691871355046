@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Regular.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Regular.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Italic.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Italic.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Bold.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Bold.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Bold-Italic.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Bold-Italic.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Bold-Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-ExtraBold.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-ExtraBold.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-ExtraBold-Italic.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-ExtraBold-Italic.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-ExtraBold-Italic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Medium.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Medium.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face{
    font-family: 'JetBrains Mono';
    src: url('/fonts/JetBrainsMono-1.0.6/web/eot/JetBrainsMono-Medium-Italic.eot') format('embedded-opentype'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff2/JetBrainsMono-Medium-Italic.woff2') format('woff2'),
         url('/fonts/JetBrainsMono-1.0.6/web/woff/JetBrainsMono-Medium-Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Suez+One:400,700&display=swap&subset=latin-ext');

$global-font-family: Fira Sans, Microsoft Yahei, Segoe UI, Helvetica, Arial, sans-serif, Segoe UI Emoji;
$global-font-secondary-color: #161209;
$global-font-secondary-color-dark: #8d8d98;
$code-font-family: JetBrains Mono, Fira Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace;

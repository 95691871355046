header { 
    .header-title {
      font-family: Suez One, JetBrains Mono, monospace;
      font-weight: normal;
    }
}

.home-subtitle {
  font-family: JetBrains Mono, Fira Mono, Source Code Pro, Menlo, Consolas, Monaco, monospace;
}

.single .content p {
  margin: 1.5rem 0;
}